import { rest } from 'msw';

import { InvoicePreCancelled, InvoicePreCancelledDetail } from './invoicePreCancelled';
import { GetOrderNumber } from './orderHandler';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api/v1/`;

export const invoicing = [
  rest.get(`${URL_BASE}invoicing/generated-invoices/`, InvoicePreCancelled),
  rest.get(`${URL_BASE}invoicing/generated-invoices/:uuidInvoice/`, InvoicePreCancelledDetail),
  rest.get(`${URL_BASE}orders/:orderNumber/items/`, GetOrderNumber),
];
