import React, { forwardRef, Ref } from 'react';
import clsx, { ClassArray, ClassDictionary } from 'clsx';
import type { Theme } from '@mui/material/styles';

import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select as SelectMui,
  BaseSelectProps,
  MenuProps,
  MenuItem as MenuItemMui,
  ListItemText as ListItemTextMui,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import { TextField } from '@design-system';
import IconChevronDown from '@experimental-components/IconsComponents/ChevronBase';

const SelectRoot = withStyles((theme: Theme) => ({
  select: ({ value }: { value?: unknown }) => ({
    display: 'flex',
    alignItems: 'center',
    color: !value ? theme.palette.darkGrey[50] : 'initial',
    borderRadius: theme.shape.borderRadius,
    padding: '11px 12px',
    paddingRight: `${theme.typography.pxToRem(36)} !important`,
    gap: '8px',
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },

    '& span': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },

  disabled: {
    backgroundColor: theme.palette.darkGrey[2],
  },
}))(SelectMui);

const InputLabelRoot = withStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.common.black,
    position: 'relative',
    lineHeight: 1,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: 14,
  },
}))(FormHelperText);

const SelectItem = withStyles(() => ({
  root: {
    padding: 8,
    paddingLeft: 12,
    fontSize: 14,
  },
}))(MenuItemMui);

const SelectItemText = withStyles(() => ({
  primary: {
    fontSize: 14,
  },
}))(ListItemTextMui);

const useStyles = makeStyles((theme: Theme) => ({
  iconBase: {
    display: 'flex',
    position: 'absolute',
    right: 16,
  },
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}));

interface Props extends BaseSelectProps {
  helperText?: string;
  errorMsg?: string;
  hideLabel?: boolean;
  hideLabelHelperText?: boolean;
  icon?: React.ReactNode;
  ghost?: boolean;
}
const Select = forwardRef(function Select(
  {
    id,
    error,
    fullWidth,
    label,
    disabled,
    required,
    errorMsg,
    helperText,
    hideLabel,
    hideLabelHelperText,
    icon,
    ghost,
    ...props
  }: Props,
  ref: Ref<HTMLSelectElement>,
) {
  const classes = useStyles();

  const menuProps: Partial<MenuProps> = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const iconComponent = (iconProps: {
    className: string | number | boolean | ClassArray | ClassDictionary | null | undefined;
  }) =>
    icon ? (
      <div className={classes.iconBase}>{icon}</div>
    ) : (
      <IconChevronDown className={clsx(iconProps.className, classes.iconRoot)} color="#000" />
    );

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required} variant="standard">
      {!hideLabel && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}

      <SelectRoot
        ref={ref}
        disabled={disabled}
        IconComponent={iconComponent}
        input={<TextField ghost={ghost} hideLabel={hideLabel} hideLabelHelperText={hideLabelHelperText} />}
        MenuProps={menuProps}
        {...props}
      />
      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
});

export { SelectItem, SelectItemText };

export default Select;
