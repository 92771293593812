import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';
import { config as amplitudeConfig } from 'providers/root/AmplitudeProvider/events';

import TopNavigationBar from '@experimental-components/TopNavigationBar';
import { NavigationType } from '@experimental-components/TopNavigationBar/types';

import { useFeatures } from 'FeatureProvider';
import { useUser } from 'containers/Users';

import { StoreProps } from 'hooks/navigation/interfaces';
import useUserStore from 'hooks/useUserStore';
import useOrganization from 'hooks/Organization/useOrganization';
import useNavigation from 'hooks/navigation/useNavigation';
import useLicenseState from 'hooks/useLicenseState';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';

import history from 'utils/history';
import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import Button from './ButtonNav';
import BannerErrorPublishMenus from './BannerErrorPublishMenus';

import { getTitle, getLabelButton, relocatePage } from './utils';
import useStyles from './styles';

function TopNavigationBarStores() {
  const classes = useStyles();
  const { t } = useTranslation(['menuMaker']);
  const location = useLocation();
  const { logEvent } = useAmplitude();

  const { remove: resetUser, refetch: loadGetUser } = useUser();

  const { flags } = useFeatures();
  const { organization } = useOrganization();
  const { modeDraft, setModeDraft, isPublishMenuError } = useSidebarLayout();
  const { licenseStatus } = useLicenseState();
  const { drawerIsDirty } = useMenuMakerDrawerState();
  const {
    storeState: { stores = [], storeDraft, selected: storeSelected },
    setStore,
    storeBackup,
    setStoreBackup,
  } = useUserStore();
  const {
    currentNavigation: typeNavigation,
    setNavigation,
    reloadStoreOnClose,
    setReloadStoreOnClose,
    changeLocationState,
    setChangeLocationState,
    goToMenuDraft,
    setGoToMenuDraft,
  } = useNavigation();

  const [wasClicked, setWasClicked] = useState<boolean>(false);
  const [elMenu, setMenu] = useState<HTMLElement | null>(null);
  const [stateChangeStoreBackup, setStateChangeStoreBackup] = useState<string>('');

  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);
  const isPathMenu = location.pathname.startsWith('/menus');
  const isPathSubscription = location.pathname.startsWith('/subscription');
  const showButtonOrganizationMenu = storeDraft && isPathMenu;
  const showButtonOrganizationSubscription = storeDraft && isPathSubscription;

  useEffect(() => {
    if (storeSelected?.isDraft) {
      setNavigation(NavigationType.STORE_DRAFT);
      setModeDraft(true);
    }
  }, []);

  useEffect(() => {
    if (!storeSelected?.isDraft && !goToMenuDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleReloadInformationStore(stateChangeStoreBackup);
    }

    if (!storeSelected?.isDraft && goToMenuDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleLoadNavigationStoreDraft();
    }

    if (storeSelected?.isDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleLoadNavigationStore();
    }
  }, [changeLocationState, reloadStoreOnClose]);

  const title = useMemo(() => {
    let level = '';
    if (typeNavigation === NavigationType.ORGANIZATION) {
      level = organization?.name || '';
    }

    if (typeNavigation === NavigationType.STORE_DRAFT) {
      level = isPathSubscription
        ? `${t('common:topNavigationBar.organization')} - ${organization?.name}`
        : t('common:topNavigationBar.menuStoreDraft');
    }

    return getTitle(typeNavigation, level, storeSelected?.name);
  }, [storeSelected, typeNavigation]);

  const showChevron = useMemo(() => {
    return typeNavigation === NavigationType.STORE && stores.length > 1;
  }, [typeNavigation, stores]);

  function handleChevronClick(element: HTMLElement) {
    setMenu(element);
    setWasClicked(!wasClicked);
  }

  function onCloseList() {
    setWasClicked(false);
  }

  function handleOnSelectedStore(values: string) {
    if (storeSelected?.uuid !== values) {
      relocatePage(location, history.replace);
      onCloseList();
      if (drawerIsDirty) {
        setChangeLocationState(true);
        return setStateChangeStoreBackup(values);
      }

      handleReloadInformationStore(values);
    } else {
      onCloseList();
    }
  }

  const handleReloadInformationStore = (values: string) => {
    const changeStore = stores.find((store: StoreProps) => store.uuid === values);
    setStoreBackup(changeStore);
    setStore(changeStore);
    logEvent('@event.$store.viewStore', '', {
      store: changeStore?.uuid,
      isDraft: changeStore?.isDraft,
      license: licenseStatus,
    });

    loadGetUser();
    resetUser();
  };

  function handleLoadNavigationStore() {
    const changeStore = storeBackup ?? stores[0];
    setModeDraft(false);
    setStore(changeStore);
    setNavigation(NavigationType.STORE);
    logEvent('@event.$store.viewStore', '', {
      store: changeStore?.uuid,
      isDraft: changeStore?.isDraft,
      license: licenseStatus,
    });
    resetUser();
  }

  function handleLoadNavigationStoreDraft() {
    setStoreBackup(storeSelected);
    setModeDraft(true);
    setNavigation(NavigationType.STORE_DRAFT);
    setStore(storeDraft);
    logEvent('@event.$store.viewStore', '', {
      store: storeDraft?.uuid,
      isDraft: storeDraft?.isDraft,
      license: licenseStatus,
    });
    resetUser();
  }

  function handleGoToStoreDraftMenu() {
    history.replace({ search: '' });

    if (typeNavigation === NavigationType.STORE_DRAFT) {
      if (drawerIsDirty) {
        return setChangeLocationState(true);
      }
      return handleLoadNavigationStore();
    }

    if (typeNavigation === NavigationType.STORE) {
      if (drawerIsDirty) {
        setGoToMenuDraft(true);
        return setChangeLocationState(true);
      }

      logEvent('@event.$store.organizationSwitched', '', {
        organization_uuid: organization?.uuid,
        module_from: amplitudeConfig['@@section'].menu,
      });
      handleLoadNavigationStoreDraft();
    }
  }

  const handleGotoStoreDraftSubscription = () => {
    switch (typeNavigation) {
      case NavigationType.STORE: {
        logEvent('@event.$store.organizationSwitched', '', {
          organization_uuid: organization?.uuid,
          module_from: amplitudeConfig['@@section'].subscription,
        });

        handleLoadNavigationStoreDraft();
        break;
      }

      case NavigationType.STORE_DRAFT: {
        handleLoadNavigationStore();
        break;
      }

      default:
        break;
    }

    history.replace({ search: '' });
  };

  function handleNavigationButtonOrganization() {
    switch (true) {
      case isPathMenu: {
        handleGoToStoreDraftMenu();
        break;
      }

      case isPathSubscription: {
        handleGotoStoreDraftSubscription();
        break;
      }

      default:
        break;
    }
  }

  return (
    <>
      <TopNavigationBar
        chevronDown={wasClicked}
        onClickChevron={handleChevronClick}
        showChevron={showChevron}
        title={title}
        type={typeNavigation}
      >
        <Box className={classes.buttonSection}>
          {(showButtonOrganizationMenu || showButtonOrganizationSubscription) && (
            <Button onClick={handleNavigationButtonOrganization}>
              <Box className={classes.labelBtnOrganization}>{t(getLabelButton(typeNavigation))}</Box>
            </Button>
          )}
        </Box>
      </TopNavigationBar>
      {modeDraft && isPathMenu && <Box className={classes.messageModeDraft}>{t('menuMaker:messages.modeDraft')}</Box>}

      {enableValidateItemsAvailability && isPublishMenuError && !modeDraft && <BannerErrorPublishMenus t={t} />}

      <TopNavigationBar.List anchorEl={elMenu} onClose={onCloseList} open={wasClicked}>
        {stores.map((store: StoreProps) => (
          <TopNavigationBar.ListItem
            key={store.uuid}
            onClick={handleOnSelectedStore}
            text={store.name}
            value={store.uuid}
          />
        ))}
      </TopNavigationBar.List>
    </>
  );
}

export default TopNavigationBarStores;
