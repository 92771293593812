// @ts-nocheck
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import camelCase from 'lodash/camelCase';
import { useIsFetching } from 'react-query';

import MultipleSelect from '@design-system/MultipleSelect';
import DateRangePickerV2 from '@experimental-components/MaterialPickersDaterangeV2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ContentFiltersReports from 'components/ContentFiltersReports';

import useFiltersReports from 'hooks/useFiltersReports';

import { getDateOptionsToReportsV3 } from 'utils/dateRanges';
import { formatTypesDates } from 'utils/times';
import { rangesValues } from 'utils/dateRangesTyped';
import filtersTypes from 'utils/reports/filtersTypes';

import { useFilters, useReportFilters } from 'containers/ReportModifiers/hooks';

import useStyles from './styles';

export interface FilterProps {
  startDateFilter?: string | Dayjs;
  endDateFilter?: string | Dayjs;
  brandsFilter?: string[];
  providersFilter?: string[];
}

function Filters() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { filters: filtersState, setFilters } = useFilters();
  const { filtersReports, setFilterReports, removeFilters } = useFiltersReports();
  const reportsFilters = useReportFilters();
  const { t } = useTranslation('reports');
  const [valuesDate, setValuesDate] = useState(rangesValues.TODAY);
  const [brandsSelected, setBrandsSelected] = useState<string[] | undefined>();
  const [providersSelected, setProvidersSelected] = useState<string[] | undefined>();
  const isFetchingModifiers = useIsFetching();
  const disableFilters = reportsFilters.isFetching || !!isFetchingModifiers;
  const starHours = '00:00:00';
  const endHours = '23:59:59';
  const minStartDateAllowed = '2023-08-15 00:00:00';

  useEffect(() => {
    reportsFilters?.refetch();
  }, []);

  useEffect(() => {
    if (reportsFilters.data?.data) {
      const brandsInit = reportsFilters.data?.data.brands.map((brand) => brand.uuid);
      let providersInit = reportsFilters.data?.data.providers ?? filtersState.providers;
      let values = {
        startDateFilter: `${dayjs(valuesDate[0]).format(formatTypesDates.SEND_FORM)} ${starHours}`,
        endDateFilter: `${dayjs(valuesDate[1]).format(formatTypesDates.SEND_FORM)} ${starHours}`,
        brandsFilter: brandsInit,
        providersFilter: providersInit,
      };

      const initLocation = filtersReports?.location as string;

      if (filtersReports && initLocation.startsWith('/reports/modifiers')) {
        const startValueInit = filtersReports?.startDate as Dayjs;
        const endDateValueInit = filtersReports?.endDate as Dayjs;
        setValuesDate([startValueInit, endDateValueInit]);
        providersInit = filtersReports?.providers as string[];
        values = {
          ...values,
          startDateFilter: filtersReports?.startDate as string,
          endDateFilter: filtersReports?.endDate as string,
          providersFilter: filtersReports?.providers as string[],
        };
      }

      setBrandsSelected(brandsInit);
      setProvidersSelected(providersInit);
      handleOnSubmit({ ...values });
    }
  }, [reportsFilters.data?.data]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('/reports/modifiers')) {
        removeFilters();
      }
    };
  }, []);

  function handleChangeRangeDate(dates: Dayjs[]) {
    const [startDate, endDate] = dates;

    const startDateAllowed = dayjs.max(dayjs(minStartDateAllowed), dayjs(startDate));

    setValuesDate([startDateAllowed, endDate]);
    const filter = {
      [filtersTypes.START_DATE]: `${startDateAllowed.format(formatTypesDates.SEND_FORM)} ${starHours}`,
      [filtersTypes.END_DATE]: `${dayjs(endDate).format(formatTypesDates.SEND_FORM)} ${endHours}`,
    };
    handleOnSubmit({ startDateFilter: filter.startDate, endDateFilter: filter.endDate });
  }

  function handleChangeBrands(values: string[]) {
    setBrandsSelected(values);
    handleOnSubmit({ brandsFilter: values });
  }

  function handleChangeProviders(values: string[]) {
    setProvidersSelected(values);
    handleOnSubmit({ providersFilter: values });
  }

  function handleOnSubmit({
    startDateFilter = (filtersReports?.startDate as string) ?? valuesDate[0],
    endDateFilter = (filtersReports?.endDate as string) ?? valuesDate[1],
    brandsFilter = brandsSelected,
    providersFilter = providersSelected,
  }: FilterProps) {
    const values = {
      startDate: `${dayjs(startDateFilter).format(formatTypesDates.SEND_FORM)} ${starHours}`,
      endDate: `${dayjs(endDateFilter).format(formatTypesDates.SEND_FORM)} ${endHours}`,
      brands: brandsFilter,
      providers: providersFilter,
    };

    const filterStorage = {
      startDate: `${dayjs(startDateFilter).format(formatTypesDates.SEND_FORM)} ${starHours}`,
      endDate: `${dayjs(endDateFilter).format(formatTypesDates.SEND_FORM)} ${endHours}`,
      providers: providersFilter,
    };

    setFilters((filters) => ({
      ...filters,
      ...values,
    }));

    setFilterReports({
      location: location.pathname,
      ...filterStorage,
    });
  }

  return (
    <>
      <ContentFiltersReports pt={1}>
        <Box>
          <DateRangePickerV2
            disabled={disableFilters}
            isFullWidth
            label={t('common:forms.date')}
            maxNumDays={30}
            minDate={new Date(minStartDateAllowed)}
            onChange={handleChangeRangeDate}
            ranges={getDateOptionsToReportsV3(t)}
            value={valuesDate}
          />
        </Box>

        <MultipleSelect
          allItemsSelectedPlaceholder={t('reports:filters.brands.seeAll')}
          disabled={disableFilters}
          hideLabelHelperText
          label={t('reports:filters.brands.label')}
          onChange={(items) => handleChangeBrands(items)}
          options={reportsFilters.data?.data.brands.map((brand) => ({ label: brand.name, value: brand.uuid }))}
          optionsSelected={brandsSelected}
          placeholder={t('reports:filters.brands.placeholder')}
          selectAllPlaceholder={t('reports:filters.brands.seeAll')}
        />

        <MultipleSelect
          allItemsSelectedPlaceholder={t('reports:filters.providers.seeAll')}
          disabled={disableFilters}
          hideLabelHelperText
          label={t('reports:filters.providers.label')}
          onChange={(items) => handleChangeProviders(items)}
          options={reportsFilters.data?.data.providers.map((provider) => ({
            label: t(`reports:channels.${camelCase(provider)}`),
            value: provider,
          }))}
          optionsSelected={providersSelected}
          placeholder={t('reports:filters.providers.placeholder')}
          selectAllPlaceholder={t('reports:filters.providers.seeAll')}
        />
      </ContentFiltersReports>

      <Box mb={2}>
        <Typography className={classes.titleNote}>{t('reports:modifiersReport.titleNote')}</Typography>
        <Box className={classes.describeNote}>
          <Trans i18nKey="reports:modifiersReport.describeNote" />
        </Box>
      </Box>
    </>
  );
}

export default Filters;
