import camelCase from 'lodash/camelCase';

// events
const LOGIN = 'login';
const LOGIN_FAIL = 'login_fail';
const LOGOUT = 'logout';
const STORE_CHANGED = 'store_changed';
const EXPORT_REPORT = 'export_report';
const REPORT_EXPORTED = 'report_exported';
const VIEW_REPORT = 'view_report';
const REPORT_VIEWED = 'report_viewed';
const VIEW_REPORT_DETAIL = 'view_report_detail';
const VIEW_MENU_MAKER = 'view_menu_maker';
const VIEW_MENU_MAKER_DETAIL = 'view_menu_maker_detail';
const MENU_MAKER_FILTERS = 'menu_maker_filters';
const MENU_MAKER = 'menu_maker';
const MENU_MAKER_PUBLISHING = 'menu_maker_publishing';
const VIEW_COPY_MENU = 'view_copy_menu';
const COPY_MENU = 'copy_menu';
const VIEW_STORE = 'view_store';
const ORGANIZATION_SWITCHED = 'organization_switched';
const PAYMENT_CONFIGURATION_STARTED = 'payment_configuration_started';
const PAYMENT_CONFIGURATION_FINISHED = 'payment_configuration_finished';
const PAYMENT_METHOD_SAVED = 'payment_method_saved';
const ROLE_SAVED = 'role_saved';

// types and sections
const SALES = 'sales';
const DRAWERS = 'drawers';
const ORDERS = 'orders';
const PAYMENTS = 'payments';
const VOIDS = 'voids';
const DISCOUNTS = 'discounts';
const MENU = 'menu';
const CATEGORIES = 'categories';
const PRODUCTS = 'products';
const MODIFIERS = 'modifiers';
const SALES_PER_TIME = 'sales_per_time';
const PUBLICATION = 'publication';
const COPY = 'copy';
const SYNC = 'sync';
const SYNC_COPY = 'SYNC_COPY';
const INVENTORY = 'inventory';
const SUBSCRIPTION = 'subscription';

// type view report
const SUMMARY = 'summary';
const COMPLETE = 'complete';
const NEW = 'new';
const EDIT = 'edit';
const DELETE = 'delete';
const DUPLICATE = 'duplicate';
const STORE = 'store';
const MULTI_STORE = 'multistore';

// search
const STRING = 'string';

// type provider publish
const UBER = 'uber';
const DIDI = 'didi';
const RAPPI = 'rappi';
const POS = 'pos';
const ONLINE = 'online';

const PARROT = 'PARROT';
const UBER_EATS = 'UBER_EATS';
const DIDI_FOOD = 'DIDI_FOOD';

// status
const SUCCESS = 'success';
const ERROR = 'error';
const START = 'start';
const FINISH = 'finish';
const FAILED = 'failed';

export const config = {
  '@event': {
    $login: {
      [camelCase([LOGIN])]: LOGIN,
      [camelCase([LOGOUT])]: LOGOUT,
      [camelCase([LOGIN_FAIL])]: LOGIN_FAIL,
    },

    $reports: {
      [camelCase([EXPORT_REPORT])]: EXPORT_REPORT,
      [camelCase([VIEW_REPORT])]: VIEW_REPORT,
      [camelCase([REPORT_VIEWED])]: REPORT_VIEWED,
      [camelCase([REPORT_EXPORTED])]: REPORT_EXPORTED,
      [camelCase([VIEW_REPORT_DETAIL])]: VIEW_REPORT_DETAIL,
    },

    $store: {
      [camelCase([STORE_CHANGED])]: STORE_CHANGED,
      [camelCase([VIEW_STORE])]: VIEW_STORE,
      [camelCase([ORGANIZATION_SWITCHED])]: ORGANIZATION_SWITCHED,
    },

    $menuMaker: {
      [camelCase([VIEW_MENU_MAKER])]: VIEW_MENU_MAKER,
      [camelCase([VIEW_MENU_MAKER_DETAIL])]: VIEW_MENU_MAKER_DETAIL,
      [camelCase([MENU_MAKER_FILTERS])]: MENU_MAKER_FILTERS,
      [camelCase([MENU_MAKER])]: MENU_MAKER,
      [camelCase([MENU_MAKER_PUBLISHING])]: MENU_MAKER_PUBLISHING,
      [camelCase([VIEW_COPY_MENU])]: VIEW_COPY_MENU,
      [camelCase([COPY_MENU])]: COPY_MENU,
    },

    $subscription: {
      [camelCase([PAYMENT_CONFIGURATION_STARTED])]: PAYMENT_CONFIGURATION_STARTED,
      [camelCase([PAYMENT_CONFIGURATION_FINISHED])]: PAYMENT_CONFIGURATION_FINISHED,
      [camelCase([PAYMENT_METHOD_SAVED])]: PAYMENT_METHOD_SAVED,
    },

    $workforce: {
      [camelCase([ROLE_SAVED])]: ROLE_SAVED,
    },
  },

  '@@type': {
    [camelCase([SUMMARY])]: SUMMARY,
    [camelCase([COMPLETE])]: COMPLETE,
    [camelCase([SALES])]: SALES,
    [camelCase([DRAWERS])]: DRAWERS,
    [camelCase([ORDERS])]: ORDERS,
    [camelCase([PAYMENTS])]: PAYMENTS,
    [camelCase([VOIDS])]: VOIDS,
    [camelCase([DISCOUNTS])]: DISCOUNTS,
    [camelCase([NEW])]: NEW,
    [camelCase([EDIT])]: EDIT,
    [camelCase([DELETE])]: DELETE,
    [camelCase([DUPLICATE])]: DUPLICATE,
    [camelCase([UBER_EATS])]: UBER,
    [camelCase([DIDI_FOOD])]: DIDI,
    [camelCase([RAPPI])]: RAPPI,
    [camelCase([PARROT])]: POS,
    [camelCase([ONLINE])]: ONLINE,
    [camelCase([COPY_MENU])]: COPY,
    [camelCase([SYNC_COPY])]: SYNC,
    [camelCase([STORE])]: STORE,
    [camelCase([MULTI_STORE])]: MULTI_STORE,
  },

  '@@section': {
    [camelCase([SALES])]: SALES,
    [camelCase([DRAWERS])]: DRAWERS,
    [camelCase([ORDERS])]: ORDERS,
    [camelCase([PAYMENTS])]: PAYMENTS,
    [camelCase([VOIDS])]: VOIDS,
    [camelCase([DISCOUNTS])]: DISCOUNTS,
    [camelCase([SUMMARY])]: SUMMARY,
    [camelCase([MENU])]: MENU,
    [camelCase([CATEGORIES])]: CATEGORIES,
    [camelCase([PRODUCTS])]: PRODUCTS,
    [camelCase([MODIFIERS])]: MODIFIERS,
    [camelCase([SALES_PER_TIME])]: SALES_PER_TIME,
    [camelCase([PUBLICATION])]: PUBLICATION,
    [camelCase([SUBSCRIPTION])]: SUBSCRIPTION,
    [camelCase([INVENTORY])]: INVENTORY,
  },

  '@@item': {
    [camelCase([MENU])]: MENU,
    [camelCase([CATEGORIES])]: CATEGORIES,
    [camelCase([PRODUCTS])]: PRODUCTS,
    [camelCase([MODIFIERS])]: MODIFIERS,
  },

  '@@search': {
    [camelCase([STRING])]: STRING,
  },

  '@@status': {
    [camelCase([SUCCESS])]: SUCCESS,
    [camelCase([ERROR])]: ERROR,
    [camelCase([START])]: START,
    [camelCase([FINISH])]: FINISH,
    [camelCase([FAILED])]: FAILED,
  },
};
