import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import ButtonScaleGray from '@experimental-components/ButtonScaleGray';
import DateRangePickerV2 from '@experimental-components/MaterialPickersDaterangeV2';
import FilterIcon from '@experimental-components/IconsComponents/Filter';

import useFiltersReports from 'hooks/useFiltersReports';

import {
  useGetReportSalesPerTimeSummary,
  useGetReportSalesPerTimeLines,
  useFilters,
} from 'containers/ReportSalesPerTime/hooks';
import { DataResponseChart, DataResponseSummary } from 'containers/ReportSalesPerTime/types';

import DisplayMessage from 'components/DisplayMessageV2';
import PageError from 'components/PageError';

import { getDateOptionsToReports } from 'utils/dateRanges';
import { rangesValues } from 'utils/dateRangesTyped';
import filtersTypes from 'utils/reports/filtersTypes';
import { formatTypesDates } from 'utils/times';

import ScheduleChip from './ScheduleChip';
import Summary from './Summary';
import ChartLinesPerTime from './ChartLines';
import ChartPiePerTime from './ChartPie';
import ChartBarPerTime from './ChartBars';
import SkeletonReport from './SkeletonReport';
import { SchedulesType } from './types';
import useStyles from './styles';

interface Props {
  t: (value: string) => string;
  onToggleDrawer: () => void;
  schedulesData?: SchedulesType[];
}

export interface FilterProps {
  startDateFilter?: string | Dayjs;
  endDateFilter?: string | Dayjs;
}

const ReportSalesPerTime = ({ t, onToggleDrawer, schedulesData }: Props) => {
  const classes = useStyles({});
  const location = useLocation();
  const history = useHistory();
  const [valuesDate, setValuesDate] = useState(rangesValues.TODAY);
  const { filters, setFilters } = useFilters();
  const { filtersReports, setFilterReports, removeFilters } = useFiltersReports();
  const {
    data,
    loadSalesPerTimeSummary,
    salesPerTimeSummaryState,
    removeSalesPerTimeSummary,
  } = useGetReportSalesPerTimeSummary();
  const {
    dataChartLines,
    loadSalesPerTimeLines,
    salesPerTimeLinesState,
    removeSalesPerTimeLines,
  } = useGetReportSalesPerTimeLines();

  useEffect(() => {
    let values = {
      startDateFilter: dayjs(valuesDate[0]).format(formatTypesDates.SEND_FORM),
      endDateFilter: dayjs(valuesDate[1]).format(formatTypesDates.SEND_FORM),
    };

    const initLocation = filtersReports?.location as string;

    if (filtersReports && initLocation.startsWith('/reports/sales-per-time')) {
      const startValueInit = filtersReports?.startDate as Dayjs;
      const endDateValueInit = filtersReports?.endDate as Dayjs;

      setValuesDate([dayjs(startValueInit), dayjs(endDateValueInit)]);

      values = {
        startDateFilter: filtersReports?.startDate as string,
        endDateFilter: filtersReports?.endDate as string,
      };
    }

    handleOnSubmit({ ...values });
  }, []);

  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      loadSalesPerTimeAllData();
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('/reports/sales-per-time')) {
        removeFilters();
      }

      removeSalesPerTimeSummary();
      removeSalesPerTimeLines();
    };
  }, []);

  const loadSalesPerTimeAllData = () => {
    loadSalesPerTimeSummary();
    loadSalesPerTimeLines();
  };

  function handleChangeRangeDate(dates: Dayjs[]) {
    const [startDate, endDate] = dates;

    setValuesDate([dayjs(startDate), dayjs(endDate)]);

    const filter = {
      [filtersTypes.START_DATE]: dayjs(startDate).format(formatTypesDates.SEND_FORM),
      [filtersTypes.END_DATE]: dayjs(endDate).format(formatTypesDates.SEND_FORM),
    };

    handleOnSubmit({ startDateFilter: filter.startDate, endDateFilter: filter.endDate });
  }

  function handleOnSubmit({ startDateFilter, endDateFilter }: FilterProps) {
    const values = {
      startDate: dayjs(startDateFilter).format(formatTypesDates.SEND_FORM),
      endDate: dayjs(endDateFilter).format(formatTypesDates.SEND_FORM),
    };

    setFilters({ ...values });
    setFilterReports({ location: location.pathname, ...values });
  }

  function renderBody() {
    const dataSummaries = data[0] as DataResponseSummary;
    const dataChartPie = data[1] as DataResponseChart;
    const dataChartBar = data[2] as DataResponseChart;

    if (salesPerTimeSummaryState.fetching || salesPerTimeLinesState?.fetching) {
      return <SkeletonReport includeHeaders={false} />;
    }

    if (salesPerTimeSummaryState.error || salesPerTimeLinesState.error) {
      return (
        <Box alignContent="center" display="flex" height="50vh">
          <PageError
            id="schedulesError"
            labelAction={t('common:buttons.retry')}
            message={t('common:errors.loaded.data')}
            onAction={loadSalesPerTimeAllData}
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (
      data &&
      !dataSummaries?.showReport &&
      !dataChartPie?.showReport &&
      !dataChartBar?.showReport &&
      dataChartLines &&
      !dataChartLines?.showReport
    ) {
      return (
        <DisplayMessage
          data-testid="paymentMethodsNoData"
          id="paymentMethodsNoData"
          maxHeight={400}
          maxWidth={450}
          message={t('common:messages.emptyInformation')}
          sizeIcon={100}
          title={t('common:messages.wereSorry')}
        />
      );
    }

    return (
      <Box className={classes.containerReport}>
        <Summary dataSummaries={dataSummaries} t={t} />

        <Box>
          <Box className={clsx(classes.titleSection, classes.deletePaddingLeft)}>
            {t('salesPerTimeReport.labels.analysisPerHour')}
          </Box>
          <ChartLinesPerTime dataChart={dataChartLines} />
        </Box>

        <Box>
          <Box className={clsx(classes.titleSection, classes.deletePaddingLeft)}>
            {t('salesPerTimeReport.labels.percentageParticipation')}
          </Box>
          <ChartPiePerTime dataChart={dataChartPie} />
        </Box>

        <Box>
          <Box className={clsx(classes.titleSection, classes.deletePaddingLeft)}>
            {t('salesPerTimeReport.labels.salesPerSchedule')}
          </Box>
          <ChartBarPerTime dataChart={dataChartBar} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography className={classes.subtitle}>{t('salesPerTimeReport.subtitle')}</Typography>

      <Box className={classes.filters}>
        <Box>
          <DateRangePickerV2
            disabled={salesPerTimeSummaryState.fetching}
            label={t('common:forms.date')}
            onChange={handleChangeRangeDate}
            ranges={getDateOptionsToReports(t)}
            value={valuesDate}
          />
        </Box>

        <Box className={classes.wrapperChip}>
          {schedulesData?.map((schedule) => (
            <ScheduleChip
              key={schedule.uuid}
              color={`#${schedule.color}`}
              label={schedule.name}
              value={`${schedule.startHour} - ${schedule.endHour}`}
            />
          ))}
        </Box>

        <ButtonScaleGray disabled={salesPerTimeSummaryState.fetching} icon={<FilterIcon />} onClick={onToggleDrawer}>
          {t('salesPerTimeReport.buttons.setSchedules')}
        </ButtonScaleGray>
      </Box>

      {renderBody()}
    </Box>
  );
};

export default ReportSalesPerTime;
