import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

export const TableRowStyled = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing(1.5),
    paddingInline: theme.spacing(4),
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.darkGrey[2],
    },
    '& > p': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))(TableRow);

export const TooltipStyled = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    lineHeight: '1.7',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
  },

  wrapperSaleReportSummary: {
    boxShadow: 'inset 0px -5px 40px rgba(196, 208, 237, 0.15)',
    marginTop: theme.spacing(3),
  },

  boldRow: {
    '& > p': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  textDisabled: {
    color: theme.palette.darkGrey[50],
    textAlign: 'end',
  },

  strikethroughCell: {
    textDecoration: 'line-through',
  },

  describeDiscountOrder: {
    paddingTop: theme.spacing(1),
    '& > strong': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  headSkeleton: {
    display: 'flex',
    justifyContent: 'center',
    paddingBlock: theme.spacing(1),
  },

  head: {
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    '&>span': {
      columnGap: theme.spacing(0.4),
      lineHeight: '1.2',
    },
  },

  rootWrapperSummary: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(48.5%, 48.5%))',
    alignItems: 'start',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, minmax(47%, 47%))',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  titleSalesPeriod: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(3),
    },
  },

  wrapperChartSalesPeriod: {
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.pxToRem(340),
    justifyContent: 'center',
  },

  boxLegendsPeriod: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down(1180)]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > :not(:first-child)': {
        marginLeft: 0,
        marginTop: theme.spacing(1),
      },
    },
  },
}));

export default useStyles;
