import React, { useEffect } from 'react';
import { init as commandbarInit } from 'commandbar';
import { useHistory } from 'react-router-dom';

import { useQuery } from 'react-query';

import { hideCommandBar } from 'utils/featureFlags/commandBar';

import useUserStore from '../hooks/useUserStore';

import { useAuth } from '../hooks/useAuth';
import useBrands from '../providers/root/BrandsProvider/useBrands';

const style = {
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: window.innerWidth <= 600 ? '370px' : '370px',
};

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api`;

const initializeCommandBar = (currentStore, accessToken, audience, user) => {
  commandbarInit('611049c2', { debug: false });

  if (!currentStore?.uuid) {
    return;
  }

  const loggedInUserId = user.uuid;
  window.CommandBar.boot(
    loggedInUserId,
    { storeUuid: currentStore.uuid, ...audience, userUuid: user.uuid },
    {
      canOpenEditor: false,
      copilotAPIHeaders: { Authorization: `Bearer ${accessToken}` },
    },
  );

  const themeMode = window.innerWidth <= 600 ? 'mobile' : 'desktop';
  window.CommandBar.setThemeMode(themeMode);
};

const addProductRecords = async (query, currentStore, accessToken) => {
  // Don't search before we have 3 characters or more
  if (query?.length < 3) {
    return [];
  }

  const response = await fetch('https://automation.parrotsoftware.io/webhook/command-bar/products/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      searchTerm: query,
      storeUuid: currentStore?.uuid,
    }),
  });
  if (!response.ok) {
    return [];
  }

  const recordReturn = await response.json();

  const products = recordReturn?.data || [];
  return products.map((item) => ({
    name: item.name,
    uuid: item.uuid,
    description: item.description || '',
    image: item.image,
    photo: item.image,
    icon: item.image,
    type: 'record-preview-with-image',
  }));
};

const addInvoiceRecords = async (query, currentStore, accessToken) => {
  // For performance, check if query is at least 4 characters and alphanumeric with hyphens
  if (!/^[a-zA-Z0-9-]{4,}$/.test(query)) {
    return [];
  }

  const response = await fetch('https://automation.parrotsoftware.io/webhook/command-bar/invoices/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      searchTerm: query,
      storeUuid: currentStore?.uuid,
    }),
  });
  if (!response.ok) {
    return [];
  }

  const recordReturn = await response.json();
  const orders = recordReturn?.data || [];
  return orders.map((item) => ({
    uuid: item.uuid,
    reference: `Orden: ${item.reference}`,
    invoicingCode: `Código: ${item.invoicingCode}`,
    pdfFileUrl: item.pdfFileUrl || '',
    xmlFileUrl: item.xmlFileUrl || '',
  }));
};

const registerSurvey = async (surveyData) => {
  await fetch('https://automation.parrotsoftware.io/webhook/command-bar/register-survey', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(surveyData),
  });
};

const addUserRecords = async (accessToken, currentStore) => {
  const response = await fetch(`${URL_BASE}/v1/stores/${currentStore.uuid}/users/?page=1&page_size=100`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    return [];
  }

  const recordReturn = await response.json();
  const users = recordReturn?.data || [];
  return users.map((item) => ({
    uuid: item.uuid,
    email: item.email,
    name: [item.firstName, item.lastName].join(' '),
    firstName: item.firstName,
    lastName: item.lastName,
  }));
};

const addStoreRecords = (allStores) => {
  window.CommandBar.addArgumentChoices('store', allStores, { labelKey: 'name' });
};

const addBrandRecords = (allBrands) => {
  window.CommandBar.addArgumentChoices('brand', allBrands, { labelKey: 'name' });
};

const addTimePeriodChoices = () => {
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const formatDateTime = (date, isEndOfDay = false) => {
    const d = new Date(date);
    d.setHours(isEndOfDay ? 23 : 0, isEndOfDay ? 59 : 0, isEndOfDay ? 59 : 0, isEndOfDay ? 999 : 0);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    const dateTimeFormat = new Intl.DateTimeFormat('sv-SE', options);

    const [datePart, timePart] = dateTimeFormat.format(d).split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const dateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return dateTime;
  };

  const getMonday = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = day === 0 ? -6 : 1 - day;
    d.setDate(d.getDate() + diff);
    return d;
  };

  const choices = [
    {
      name: 'Hoy',
      key: 'today',
      startDate: encodeURIComponent(formatDateTime(today)),
      endDate: encodeURIComponent(formatDateTime(today, true)),
      icon: '👇',
    },
    {
      name: 'Ayer',
      key: 'yesterday',
      startDate: encodeURIComponent(formatDateTime(new Date(today.getTime() - oneDay))),
      endDate: encodeURIComponent(formatDateTime(new Date(today.getTime() - oneDay), true)),
      icon: '👈',
    },
    {
      name: 'Semana Actual',
      key: 'currentWeek',
      startDate: encodeURIComponent(formatDateTime(getMonday(today))),
      endDate: encodeURIComponent(formatDateTime(new Date(getMonday(today).getTime() + 6 * oneDay), true)),
    },
    {
      name: 'Mes Actual',
      key: 'currentMonth',
      startDate: encodeURIComponent(formatDateTime(new Date(today.getFullYear(), today.getMonth(), 1))),
      endDate: encodeURIComponent(formatDateTime(new Date(today.getFullYear(), today.getMonth() + 1, 0), true)),
    },
    {
      name: 'Año Actual',
      key: 'currentYear',
      startDate: encodeURIComponent(formatDateTime(new Date(today.getFullYear(), 0, 1))),
      endDate: encodeURIComponent(formatDateTime(new Date(today.getFullYear(), 11, 31), true)),
    },
    { name: 'Otro', key: 'other', startDate: null, endDate: null },
  ];

  window.CommandBar.addArgumentChoices('timePeriod', choices, {
    labelKey: 'name',
    defaultIcon:
      '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><rect height="3.9" stroke="#fff" stroke-width="1.5" width="4" x="2.75" y="17.3496"></rect><rect height="7" stroke="#fff" stroke-width="1.5" width="4" x="9.94995" y="14.25"></rect><rect height="10" stroke="#fff" stroke-width="1.5" width="4" x="17.25" y="11.25"></rect><path d="M4 13L20 4" stroke="#fff" stroke-width="1.5"></path><path d="M17 3L20.5 3.5L20 7" stroke="#fff" stroke-width="1.5"></path></svg>',
    descriptionKey: 'key',
  });
};

const addRouter = (history) => {
  const routerFunc = (newUrl) => {
    history.push(newUrl);
  };
  window.CommandBar.addRouter(routerFunc);
};

function SearchBox() {
  const {
    storeState: { selected: currentStore, stores: allStores },
  } = useUserStore();
  const { accessToken, user } = useAuth();
  const { brands, getBrands } = useBrands();
  const { data: audience } = useQuery('audience', async () => {
    const request = await fetch(
      `https://automation.parrotsoftware.io/webhook/command-bar/audience?userUuid=${user.uuid}&storeUuid=${currentStore.uuid}`,
    );
    const data = await request.json();
    return data.contactAudience;
  });

  const hiddenStyle = hideCommandBar ? { display: 'none' } : {};

  useEffect(() => {
    setTimeout(() => {
      getBrands();
    }, 0);
  }, [currentStore]);

  const handlersEvents = (eventName, eventData) => {
    if (eventName !== 'survey_response') {
      return;
    }
    const answer = {
      userUuid: eventData.userAttributes.userUuid,
      storeUuid: eventData.userAttributes.storeUuid,
      surveyName: eventData.nudge.slug,
      surveyId: parseInt(eventData.nudge.id, 10),
      surveyStep: eventData.nudge.step.title,
      surveyStepId: eventData.nudge.step.id,
      surveyResponse: eventData.response.value,
    };
    registerSurvey(answer);
  };

  useEffect(() => {
    let unsubPromise = new Promise(() => {});
    if (audience && user) {
      initializeCommandBar(currentStore, accessToken, audience, user);
      window.CommandBar.addRecords('products', [], {
        onInputChange: (query) => addProductRecords(query, currentStore, accessToken),
      });
      window.CommandBar.addRecords('invoices', [], {
        onInputChange: (query) => addInvoiceRecords(query, currentStore, accessToken),
      });
      window.CommandBar.addRecords('users', () => addUserRecords(accessToken, currentStore));
      addStoreRecords(allStores);
      addBrandRecords(brands);
      addTimePeriodChoices();
      unsubPromise = window.CommandBar.addEventSubscriber(handlersEvents);
    }

    return () => {
      if (window.CommandBar) {
        window.CommandBar.shutdown();
        unsubPromise.then((unsub) => unsub());
      }
    };
  }, [currentStore, brands, audience, user]);

  const history = useHistory();

  useEffect(() => {
    if (audience) {
      addRouter(history);
    }
  }, [history, audience]);

  return (
    <div style={style}>
      <div id="commandbar-inline-root" style={hiddenStyle}></div>
    </div>
  );
}

export default SearchBox;
