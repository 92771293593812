/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserCurrentStore } from 'utils/auth';

export const getEnableWsOptExportModifierReport = (flags: { [key: string]: any }) => {
  const currentStore = getUserCurrentStore()?.uuid;

  if (typeof flags?.ffEnableWsOptExportModifierReport === 'boolean') return flags?.ffEnableWsOptExportModifierReport;

  return flags?.ffEnableWsOptExportModifierReport?.storeUuidList?.includes(currentStore);
};
